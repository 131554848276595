import ReactDOM from 'react-dom/client';
import App from 'app/App';
import { BrowserRouter as Router } from 'react-router-dom';
// import 'shared/config/sentry/sentry';
import 'shared/config/i18n/i18n';
import { AuthProvider } from 'app/providers/auth/AuthProvider';

import { ErrorBoundary } from 'app/providers/ErrorBoundary';
import QueryClientProvider from 'app/providers/api/QueryClientProvider';
import MUThemeProvider from 'app/providers/ThemeProvider/ui/ThemeProvider';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <QueryClientProvider>
    <AuthProvider>
      <Router>
        <MUThemeProvider>
          <ErrorBoundary>
            <App />
          </ErrorBoundary>
        </MUThemeProvider>
      </Router>
    </AuthProvider>
  </QueryClientProvider>,
);
