import { MouseEventHandler, useMemo } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Link, List, ListItem, Skeleton, Typography } from '@mui/material';
import { MapsHomeWork, HelpOutlineOutlined } from '@mui/icons-material';
import { AppLink } from 'shared/ui/AppLink';
import { contentHeight } from 'shared/constants/styles';
import { getRouteHome } from 'shared/constants/routerConst';

import { UserRole } from 'shared/constants/userRole';
import useAuth from 'shared/hooks/useAuth';

export const Navbar = () => {
  const { userData, isLoading } = useAuth();
  const userRoles: Array<UserRole> = userData?.roles;
  const { t } = useTranslation();
  const location = useLocation();

  const navBarItemsList = useMemo(
    () => [
      {
        label: t('Sellers'),
        route: getRouteHome(),
        icon: MapsHomeWork,
        roles: [UserRole.REOPS_ADMIN],
      },
    ],
    [t],
  );

  return (
    <>
      <Box
        bgcolor="background.paper"
        height={contentHeight}
        minWidth="200px"
        maxWidth="260px"
        position="fixed"
        zIndex="drawer"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <List sx={{ pt: '24px' }}>
          {!isLoading ? (
            navBarItemsList.map((item) => {
              const isActive = matchPath(item.route + '/*', location.pathname);

              const isHidden = !userRoles.some((role: UserRole) =>
                item.roles.includes(role),
              );
              const Icon = item.icon;

              const disabledClick: MouseEventHandler<HTMLAnchorElement> = (
                e,
              ) => {
                e.preventDefault();
              };

              if (isHidden) {
                return null;
              }

              return (
                <ListItem
                  sx={{
                    p: 0,
                    pl: '24px',
                    ':hover': !isActive && {
                      bgcolor: 'action.hover',
                      cursor: 'pointer',
                    },
                    bgcolor: isActive && 'primary.main',
                    color: isActive && 'common.white',
                  }}
                  key={item.route}
                >
                  <Box
                    component={AppLink}
                    onClick={isActive ? disabledClick : () => {}}
                    to={item.route}
                    display="flex"
                    gap="12px"
                    alignItems="center"
                    p="12px 0"
                    width="100%"
                  >
                    <Icon
                      sx={{
                        color: isActive ? 'common.white' : 'action.active',
                      }}
                    />
                    <Typography
                      color={isActive ? 'common.white' : 'action.active'}
                    >
                      {item.label}
                    </Typography>
                  </Box>
                </ListItem>
              );
            })
          ) : (
            <Box display="flex" flexDirection="column" gap="2px">
              <Skeleton variant="rectangular" height="48px" />
              <Skeleton variant="rectangular" height="48px" />
              <Skeleton variant="rectangular" height="48px" />
            </Box>
          )}
        </List>
        <Box p="0 0 12px 24px">
          <Box
            display="flex"
            component={Link}
            href={'mailto:help@re-ops.co'}
            sx={{ textDecoration: 'none' }}
            gap="8px"
          >
            <HelpOutlineOutlined />
            <Typography typography="openSans.button" lineHeight="24px">
              {t('Help')}
            </Typography>
          </Box>
          <Typography
            mt={'12px'}
            mr={'24px'}
            align={'center'}
            style={{ opacity: '0.5' }}
          >
            {/* In near future */}
            {/* <VersionInfo /> */}
          </Typography>
        </Box>
      </Box>
      <Box height={contentHeight} minWidth="200px" maxWidth="260px" />
    </>
  );
};
