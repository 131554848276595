import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Toast.scss';

export const Toast = () => (
  <ToastContainer
    className="Toast"
    position="top-right"
    autoClose={3000}
    hideProgressBar
  />
);
