import { useState, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, IconButton } from '@mui/material';
import { ModeEditOutlineOutlined } from '@mui/icons-material';
import { EditMarketingInputModal } from 'shared/ui/EditMarketingInputModal';
import { listOfStates } from 'shared/constants/states';

export const StatesInfoForm = ({
  marketingStates,
  handleUpdateMarketingStates,
  sellerId,
}: {
  marketingStates: string[] | null;
  sellerId: string;
  handleUpdateMarketingStates: ({
    idOfSeller,
    data,
  }: {
    idOfSeller: string;
    data: string[];
  }) => void;
}) => {
  const [isEditState, setIsEditState] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const { t } = useTranslation();

  const handleSaveMarketingStates = useCallback(() => {
    handleUpdateMarketingStates({
      idOfSeller: sellerId,
      data: selectedOptions,
    });
    setIsEditState(false);
  }, [handleUpdateMarketingStates, selectedOptions, sellerId]);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValue = event.target.value;
    setSelectedOptions((prevSelected) =>
      prevSelected.includes(selectedValue)
        ? prevSelected.filter((value) => value !== selectedValue)
        : [...prevSelected, selectedValue],
    );
  };

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelectedOptions(listOfStates?.map((option) => option.value) || []);
    } else {
      setSelectedOptions([]);
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted && marketingStates) {
      setSelectedOptions(marketingStates);
    }

    return () => {
      isMounted = false;
    };
  }, [marketingStates]);

  const filteredStateLabels = useMemo(() => {
    if (!marketingStates) {
      return '';
    }

    return listOfStates
      .filter((state) => marketingStates.includes(state?.value))
      .map((state) => state.label)
      .join(', ');
  }, [marketingStates]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          position: 'relative',
          width: '100%',
        }}
      >
        <Typography mt="4px" typography="poppins.subtitle1">
          {t('Service Areas')}
        </Typography>
        <IconButton
          sx={{
            position: 'absolute',
            right: 0,
            top: '50%',
            transform: 'translateY(-50%)',
            visibility: 'hidden',
          }}
          className="editIconButton"
          onClick={() => setIsEditState(true)}
        >
          <ModeEditOutlineOutlined color="primary" />
        </IconButton>
      </Box>
      <Box
        mt={2}
        sx={{ borderBottom: '1px solid #00000020', paddingBottom: '8px' }}
      >
        {filteredStateLabels ? (
          <Typography
            mt="4px"
            sx={{ lineHeight: '26px' }}
            typography="openSans.subtitle1"
            color="#00000080"
          >
            {filteredStateLabels.split(',').length === listOfStates.length
              ? 'Nationwide'
              : filteredStateLabels}
          </Typography>
        ) : (
          <Typography
            mt="4px"
            typography="openSans.subtitle1"
            color="#00000080"
          >
            {t('No information provided')}
          </Typography>
        )}
      </Box>
      {isEditState && (
        <EditMarketingInputModal
          titleModal={t('Select Service Areas')}
          isSelect
          isModalOpen={isEditState}
          handleCloseModal={() => setIsEditState(false)}
          handleSubmitModal={handleSaveMarketingStates}
          approveNameButton={t('Save')}
          selectedOptions={selectedOptions}
          handleChange={handleCheckboxChange}
          handleSelectAll={handleSelectAll}
        />
      )}
    </>
  );
};
