import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { innerNavHeight } from 'shared/constants/styles';
import { NeedAnyAssistance } from 'shared/ui/NeedAnyAssistance/ui/NeedAnyAssistance';
import { Page } from 'shared/ui/Page';
import exclamationMark from 'shared/assets/svg/exclamation-mark.svg';

export const ErrorPage = () => {
  const { t } = useTranslation();

  const reloadPage = () => {
    location.reload();
  };

  return (
    <Box>
      <Page>
        <Box
          display="flex"
          justifyContent="center"
          height={innerNavHeight}
          sx={{ overflowY: 'scroll' }}
        >
          <Box
            width="100%"
            maxWidth="400px"
            display="flex"
            alignItems="center"
            flexDirection="column"
          >
            <Typography typography="poppins.h5" mt="40px" mb="42px">
              {t('Unknown Error')}
            </Typography>

            <Box mb="52px">
              <img src={String(exclamationMark)} alt={t('Unknown Error')} />
            </Box>

            <Typography mb="16px" typography="openSans.h6" textAlign="center">
              {t('Sorry, something went wrong')}
            </Typography>

            <Typography
              mb="76px"
              typography="openSans.body1"
              textAlign="center"
            >
              {t('Please try to refresh the page')}.
            </Typography>

            <Box mb="120px" width="100%">
              <Button onClick={reloadPage} fullWidth variant="contained">
                {t('Refresh Page')}
              </Button>
            </Box>

            <NeedAnyAssistance />
          </Box>
        </Box>
      </Page>
    </Box>
  );
};
