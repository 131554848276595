import { CancelOutlined } from '@mui/icons-material';
import { Box, Button, alpha } from '@mui/material';
import { ReactNode, useCallback } from 'react';

interface ModalContentProps {
  onClose?: () => void;
  children: ReactNode;
  buttons: ReactNode;
}

export const ModalContent = ({
  onClose,
  children,
  buttons,
}: ModalContentProps) => {
  const onCloseModal = useCallback(() => {
    if (onClose) {
      onClose();
    }
  }, [onClose]);

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Box minWidth="440px" bgcolor="background.paper">
        <Box p="16px">
          <Box
            position="sticky"
            top="16px"
            display="flex"
            justifyContent="flex-end"
          >
            <Button
              data-testid="Close-modal-button"
              onClick={onCloseModal}
              sx={{ padding: 0, minWidth: 'auto' }}
            >
              <CancelOutlined
                sx={(theme) => ({
                  color: alpha(theme.palette.common.black, 0.5),
                })}
              />
            </Button>
          </Box>
          <Box p="0 24px">{children}</Box>
        </Box>
        <Box position="sticky" bottom="0px">
          <Box
            display="flex"
            p="12px 16px 16px"
            sx={{
              bgcolor: '#f5f5f5',
            }}
          >
            {buttons}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
