export const convertToObjectSellerContactInfo = (
  inputArray: {
    id: string;
    sellerId: string;
    type: string;
    value: string | null;
  }[],
): {
  [key: string]: string;
} => {
  const output: {
    [key: string]: string | null;
  } = { sellerId: '' };

  inputArray.forEach((item) => {
    if (!output.sellerId) {
      output.sellerId = item.sellerId;
    }
    output[item.type] = item.value;
  });

  return output;
};

export const validateURL = (url: string) => {
  const urlPattern = /^(https?:\/\/[^\s$.?#].[^\s]*)$/i;
  return urlPattern.test(url);
};
