import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { dissoc } from 'rambda';
import { Box, TextField, Typography } from '@mui/material';
import { useMask } from '@react-input/mask';
import { Modal } from 'shared/ui/Modal';
import { inviteUserFields } from 'shared/constants/constants';
import { isPossiblePhoneNumber, isValidPhoneNumber } from 'libphonenumber-js';

interface InviteUserFormValues {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
}

const validationSchemaInviteUser = Yup.object({
  firstName: Yup.string()
    .min(2, 'Must be at least 2 characters')
    .max(50, 'Must be 50 characters or less')
    .required('Required'),
  lastName: Yup.string()
    .min(2, 'Must be at least 2 characters')
    .max(50, 'Must be 50 characters or less')
    .required('Required'),
  email: Yup.string()
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      'Please enter a valid email address',
    )
    .required('Required'),
  phone: Yup.string().test(
    'valid-phone-number',
    'Please enter a valid phone number e.g. (223) 532-1234',
    (value) => {
      value = value || '';
      return (
        value === '' ||
        (isPossiblePhoneNumber(value, 'US') && isValidPhoneNumber(value, 'US'))
      );
    },
  ),
});

export const InviteUserFormModal = ({
  isOpenAddUserModal,
  sellerId,
  setIsOpenAddUserModal,
  sellerSendInviteUsers,
}: {
  isOpenAddUserModal: boolean;
  sellerId: string;
  setIsOpenAddUserModal: (arg0: boolean) => void;
  sellerSendInviteUsers: ({
    idOfSeller,
    data,
  }: {
    idOfSeller: string;
    data: {
      firstName: string;
      lastName: string;
      email: string;
      phone?: string;
    };
  }) => void;
}) => {
  const { t } = useTranslation();
  const [fieldErrors, setFieldErrors] = useState<{ [key: string]: string }>({});

  const inputPhoneRef = useMask({
    mask: '(___) ___-____',
    replacement: { _: /\d/ },
    showMask: true,
    separate: true,
  });

  const formik = useFormik<InviteUserFormValues>({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
    },
    enableReinitialize: true,
    validationSchema: validationSchemaInviteUser,
    onSubmit: (values) => {
      const sendData = values.phone ? values : dissoc('phone', values);
      sellerSendInviteUsers({
        idOfSeller: sellerId,
        data: sendData,
      });
      setIsOpenAddUserModal(false);
    },
  });

  const handleSaveFormikInviteUser = async () => {
    const isValid = await formik.validateForm();
    formik.setTouched({
      firstName: true,
      lastName: true,
      email: true,
      phone: true,
    });

    if (Object.keys(isValid).length === 0) {
      formik.handleSubmit();
    }
  };

  const handleChangeField = async (
    event: React.ChangeEvent<{ name?: string; value: unknown }>,
  ) => {
    const { value, name } = event.target;

    if (!name) return;

    formik.setFieldValue(name, value);
    try {
      await validationSchemaInviteUser.validateAt(name, { [name]: value });
      setFieldErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
    } catch (err: any) {
      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        [name]: err.errors[0],
      }));
    }
  };

  return (
    <Modal
      isModalOpen={isOpenAddUserModal}
      handleCloseModal={() => setIsOpenAddUserModal(false)}
      handleSubmitModal={handleSaveFormikInviteUser}
      approveNameButton={t('Save')}
      disabled={!formik.isValid || !formik.dirty}
    >
      <Typography mb="20px" typography="poppins.subtitle2">
        {t('Invite User to Company')}
      </Typography>
      <Box my={2} display="flex" flexDirection="column" gap={3}>
        {inviteUserFields.map((field) => (
          <TextField
            key={field.name}
            name={field.name}
            error={!!fieldErrors[field.name]}
            helperText={fieldErrors[field.name]}
            value={formik.values[field.name]}
            placeholder={field.placeholder}
            label={field.label}
            onChange={handleChangeField}
            inputProps={{
              ref: field.name === 'phone' && inputPhoneRef,
              maxLength: 50,
            }}
          />
        ))}
      </Box>
    </Modal>
  );
};
