export enum UserRole {
  REOPS_ADMIN = 'ReOps Admin',
  SELLER_ADMIN = 'Seller Admin',
}

export const UserRoleByIb: { [key: string]: string } = {
  '344c493c': 'ReOps Admin',
  '6e12fbae': 'Seller Admin',
};

export enum UserStatus {
  INVITED = 'Invited',
  SUSPENDED = 'Suspended',
  UNSUSPENDED = 'Unsuspended',
}

export enum UserPermissions {}

export const acceptableRolesForApp = [
  UserRole.REOPS_ADMIN,
  UserRole.SELLER_ADMIN,
];
