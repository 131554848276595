import { UserProfile } from 'shared/types/User';

import { FETH_USER } from '../endpoints';
import request from '../request';

export default class UserService {
  static readonly fetchUser = () => {
    return request<UserProfile>({
      url: `${FETH_USER}/me`,
      method: 'get',
    });
  };

  static readonly fetchSellerId = () => {
    return request<{ sellerId: string }>({
      url: `${FETH_USER}/seller`,
      method: 'post',
    });
  };
}
