import { concat } from 'rambda';

export const camelCase = (str: string) =>
  str.replace(/[-_]([a-z])/g, (m) => m[1].toUpperCase());
export const snakeCase = (str: string) =>
  str.replace(/([A-Z])/g, (x) => concat('_', x.toLowerCase()));

export const convertKeysToCamelCase = (
  obj: Record<string, any>,
): Record<string, any> => {
  if (typeof obj !== 'object' || !obj) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map((item) => convertKeysToCamelCase(item));
  }

  const newObj: Record<string, any> = {};

  for (const [key, value] of Object.entries(obj)) {
    const newKey = camelCase(key);
    const newValue = convertKeysToCamelCase(value);
    newObj[newKey] = newValue;
  }

  return newObj;
};

export const convertKeysToSnakeCase = (
  obj: Record<string, any>,
): Record<string, any> => {
  if (typeof obj !== 'object' || !obj) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map((item) => convertKeysToSnakeCase(item));
  }

  const newObj: Record<string, any> = {};

  for (const [key, value] of Object.entries(obj)) {
    const newKey = snakeCase(key);
    const newValue = convertKeysToSnakeCase(value);
    newObj[newKey] = newValue;
  }

  return newObj;
};
