import { CssBaseline, ThemeProvider } from '@mui/material';
import { ReactNode } from 'react';
import { theme } from '../config/theme';

interface ThemeProviderProps {
  children?: ReactNode;
}

export const MUThemeProvider = ({ children }: ThemeProviderProps) => (
  <ThemeProvider theme={theme}>
    <CssBaseline>{children}</CssBaseline>
  </ThemeProvider>
);

export default MUThemeProvider;
