import { ForbiddenPage } from 'pages/ForbiddenPage';
import { NotFoundPage } from 'pages/NotFoundPage';
import {
  AppRoutes,
  getRouteForbidden,
  getRouteMain,
  getRouteLogin,
  getRouteAuth,
  getRouteNotFound,
  getRouteSellers,
  getRouteSellerPage,
  getRouteCompanySellerPage,
  getRouteMarketingSellerPage,
  getRoutePoliciesSellerPage,
  getRouteUsersSellerPage,
} from 'shared/constants/routerConst';
import { AppRoutesProps } from 'shared/types/router';
import { UserRole } from 'shared/constants/userRole';
import { AuthRedirectPage } from 'pages/AuthRedirectPage';
import { LoginRedirectPage } from 'pages/LoginRedirectPage';
import { MainPage } from 'pages/MainPage';
import { SellersPage } from 'pages/SellersPage';
import { SellerPage } from 'pages/SellerPage';
import { CompanyInfoPage } from 'pages/SellerPage/components/CompanyInfoPage/CompanyInfoPage';
import { MarketingInfoPage } from 'pages/SellerPage/components/MarketingInfoPage/MarketingInfoPage';
import { PoliciesInfoPage } from 'pages/SellerPage/components/PoliciesInfoPage/PoliciesInfoPage';
import { UsersInfoPage } from 'pages/SellerPage/components/UsersInfoPage/UsersInfoPage';

export const routeConfig: Partial<Record<AppRoutes, AppRoutesProps>> = {
  [AppRoutes.MAIN]: {
    path: getRouteMain(),
    element: <MainPage />,
  },
  [AppRoutes.LOGIN]: {
    path: getRouteLogin(),
    element: <LoginRedirectPage />,
  },
  [AppRoutes.AUTH]: {
    path: getRouteAuth(),
    element: <AuthRedirectPage />,
  },
  [AppRoutes.SELLERS]: {
    roles: [UserRole.REOPS_ADMIN],
    authOnly: true,
    path: getRouteSellers(),
    element: <SellersPage />,
  },
  [AppRoutes.SELLER]: {
    roles: [UserRole.REOPS_ADMIN, UserRole.SELLER_ADMIN],
    authOnly: true,
    path: getRouteSellerPage(':sellerId'),
    element: <SellerPage />,
    nested: {
      [AppRoutes.SELLER_COMPANY_INFO]: {
        roles: [UserRole.REOPS_ADMIN, UserRole.SELLER_ADMIN],
        path: getRouteCompanySellerPage(':sellerId'),
        element: <CompanyInfoPage />,
      },
      [AppRoutes.SELLER_MARKETING_INFO]: {
        roles: [UserRole.REOPS_ADMIN, UserRole.SELLER_ADMIN],
        path: getRouteMarketingSellerPage(':sellerId'),
        element: <MarketingInfoPage />,
      },
      [AppRoutes.SELLER_POLICIES_INFO]: {
        roles: [UserRole.REOPS_ADMIN, UserRole.SELLER_ADMIN],
        path: getRoutePoliciesSellerPage(':sellerId'),
        element: <PoliciesInfoPage />,
      },
      [AppRoutes.SELLER_USERS_INFO]: {
        roles: [UserRole.REOPS_ADMIN, UserRole.SELLER_ADMIN],
        path: getRouteUsersSellerPage(':sellerId'),
        element: <UsersInfoPage />,
      },
    },
  },
  // error pages
  [AppRoutes.FORBIDDEN]: {
    authOnly: true,
    path: getRouteForbidden(),
    element: <ForbiddenPage />,
  },
  [AppRoutes.NOT_FOUND_PAGE]: {
    path: getRouteNotFound(),
    element: <NotFoundPage />,
  },
  // last
  [AppRoutes.NOT_FOUND]: {
    path: '*',
    element: <NotFoundPage />,
  },
};
