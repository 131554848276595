import { Typography, Fade } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 684,
    padding: '6px 16px',
    backgroundColor: '#ffffff',
    color: '#000000',
    margin: '16px',
    borderRadius: '8px',
    boxShadow:
      '0px 5px 6px -3px #00000033, 0px 9px 12px 1px #00000024, 0px 3px 16px 2px #0000001F',
    '& .MuiTooltip-arrow': {
      color: '#ffffff',
    },
  },
});

export const InfoTooltip = ({ content }: { content: string }) => {
  const renderContent = () => (
    <div style={{ width: 300 }}>
      <Typography
        component="div"
        sx={{ whiteSpace: 'pre-line' }}
        typography="openSans.caption"
      >
        {content}
      </Typography>
    </div>
  );

  return (
    <CustomWidthTooltip
      arrow
      placement="right"
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 200 }}
      title={renderContent()}
    >
      <InfoOutlinedIcon color="primary" />
    </CustomWidthTooltip>
  );
};
