import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@mui/material';
import {
  getRouteHome,
  getRouteCompanySellerPage,
} from 'shared/constants/routerConst';
import { innerPageHeight } from 'shared/constants/styles';
import { NeedAnyAssistance } from 'shared/ui/NeedAnyAssistance';
import { Page } from 'shared/ui/Page';
import forbiddenIcon from 'shared/assets/svg/403.svg';
import useAuth from 'shared/hooks/useAuth';

const ForbiddenPage = () => {
  const { t } = useTranslation();
  const { invitedSellerId } = useAuth();
  const navigate = useNavigate();

  const onClick = useCallback(async () => {
    if (invitedSellerId) {
      navigate(getRouteCompanySellerPage(invitedSellerId));
    } else {
      navigate(getRouteHome());
    }
  }, [navigate, invitedSellerId]);

  return (
    <Page dataTestId="ForbiddenPage">
      <Box
        display="flex"
        justifyContent="center"
        height={innerPageHeight}
        sx={{ overflowY: 'scroll' }}
      >
        <Box
          width="100%"
          maxWidth="400px"
          display="flex"
          alignItems="center"
          flexDirection="column"
        >
          <Typography typography="poppins.h5" mt="40px" mb="42px">
            {t('Access Denied')}
          </Typography>

          <Box mb="74px">
            <img src={String(forbiddenIcon)} alt={t('Not found')} />
          </Box>

          <Typography mb="16px" typography="openSans.h6" textAlign="center">
            {t('Sorry, you don’t have permissions to access')}
          </Typography>

          <Typography mb="56px" typography="openSans.body1" textAlign="center">
            {t('Please go back to Home page')}.
          </Typography>

          <Box mb="60px" width="100%">
            <Button
              onClick={onClick}
              fullWidth
              variant="contained"
              data-testid="ForbiddenPage.Button"
            >
              {t('Go to Home Page')}
            </Button>
          </Box>

          <NeedAnyAssistance />
        </Box>
      </Box>
    </Page>
  );
};

export default ForbiddenPage;
