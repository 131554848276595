import {
  getRoutePrivacyPolicy,
  getRouteTermsOfService,
} from 'shared/constants/routerConst';

export function usePublicPageDetector() {
  const publicPages = [getRoutePrivacyPolicy(), getRouteTermsOfService()];

  return publicPages.includes(window.location.pathname);
}
