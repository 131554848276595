import { ArrowDropDownRounded } from '@mui/icons-material';
import { Avatar, Box, IconButton, Skeleton, Typography } from '@mui/material';

type UserProfile = {
  given_name?: string;
  family_name?: string;
  email?: number;
  name?: string;
};

interface UserAvatarProps {
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  isLoading: boolean;
  user: UserProfile;
}

export const UserAvatar = (props: UserAvatarProps) => {
  const { onClick, user, isLoading } = props;

  const firstName = user?.name?.split(' ')[0];
  const lastName = user?.name?.split(' ')[1];

  const userName = `${lastName || ''}, ${firstName || ''}`;
  const userNameInitials = `${(firstName || '')
    .charAt(0)
    .toUpperCase()}${lastName?.charAt(0).toUpperCase()}`;

  const onClickAction = (event: React.MouseEvent<HTMLElement>) => {
    if (onClick) {
      onClick(event);
    }
  };

  return (
    <Box
      sx={{
        ':hover': {
          cursor: 'pointer',
        },
      }}
      onClick={onClickAction}
      display="flex"
      alignItems="center"
    >
      {!isLoading ? (
        <>
          <IconButton size="small">
            <Avatar
              sx={{
                width: 32,
                height: 32,
                bgcolor: 'rgba(63, 110, 183, 0.10)',
              }}
            >
              <Typography fontSize="20px" color="primary">
                {userNameInitials}
              </Typography>
            </Avatar>
          </IconButton>
          <Typography color="black" ml="10px" mr="8px">
            {userName}
          </Typography>
          <ArrowDropDownRounded sx={{ color: 'action.active' }} />
        </>
      ) : (
        <>
          <Skeleton variant="circular" width={32} height={32} />
          <Skeleton
            sx={{ ml: '10px' }}
            variant="rounded"
            width={180}
            height={32}
          />
        </>
      )}
    </Box>
  );
};
