export const AccordionTheme = {
  MuiAccordion: {
    styleOverrides: {
      root: {
        '&.Mui-expanded': {
          margin: 0,
        },
        borderRadius: '4px',
        overflow: 'hidden',
        boxShadow: '0px 2px 6px 0px rgba(0, 0, 0, 0.10)',
      },
    },
  },
  MuiAccordionSummary: {
    styleOverrides: {
      root: {
        minHeight: '36px',
        height: '36px',

        '&.Mui-expanded': {
          minHeight: 0,
        },
        '& .MuiAccordionSummary-content.Mui-expanded': {
          margin: '8px 0',
        },
        '& .MuiAccordionSummary-content': {
          margin: '8px 0',
        },
      },
    },
  },
  MuiAccordionDetails: {
    styleOverrides: {
      root: {
        padding: '0',
      },
    },
  },
};
