import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box } from '@mui/material';
import { EditCompanyInputModal } from 'shared/ui/EditCompanyInputModal';
import { EditableTextField } from 'shared/ui/EditableTextField';
import { productOrderingFields } from 'shared/constants/constants';
import { Seller } from 'shared/types/Sellers';

interface ProductOrderingFormValues {
  applicationEmail: string;
  applicationUrl: string;
}

const validationSchemaProductOrdering = Yup.object({
  applicationEmail: Yup.string()
    .max(320, 'Must be 320 characters or less')
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      'Please enter a valid email address e.g. contact@example.com',
    )
    .required('Required'),
  applicationUrl: Yup.string()
    .url('Please enter a valid URL, e.g. https://example.com')
    .max(1745, 'Must be 1745 characters or less'),
});

export const ProductOrderingForm = ({
  sellerInfo,
  handleUpdateProductOrdering,
}: {
  sellerInfo: Seller;
  handleUpdateProductOrdering: ({
    idOfSeller,
    data,
  }: {
    idOfSeller: string;
    data: { [key: string]: string };
  }) => void;
}) => {
  const { t } = useTranslation();
  const [isEditProduct, setIsEditProduct] = useState(false);
  const [erroMessageProduct, setErroMessageProduct] = useState<string>('');
  const [fieldToEditProduct, setFieldToEditProduct] = useState<
    keyof ProductOrderingFormValues | null
  >(null);
  const [tempValueProduct, setTempValueProduct] = useState<string>('');
  const [labelToEditProduct, setLabelToEditProduct] = useState<string>('');
  const [placeholderToEditProduct, setPlaceholderToEditProduct] =
    useState<string>('');

  const formik = useFormik<ProductOrderingFormValues>({
    initialValues: {
      applicationEmail: sellerInfo?.applicationEmail || '',
      applicationUrl: sellerInfo?.applicationUrl || '',
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      const updateData = { [fieldToEditProduct]: values[fieldToEditProduct] };
      handleUpdateProductOrdering({
        idOfSeller: sellerInfo?.id,
        data: updateData,
      });
      setIsEditProduct(false);
      setTempValueProduct('');
    },
  });

  const handleEditContactClick = (
    fieldName: keyof ProductOrderingFormValues,
    fieldLabel: string,
    fieldPlaceholder: string,
  ) => {
    setFieldToEditProduct(fieldName);
    setLabelToEditProduct(fieldLabel);
    setPlaceholderToEditProduct(fieldPlaceholder);
    setTempValueProduct(formik.values[fieldName]);
    setIsEditProduct(true);
  };

  const handleSaveFormikProduct = useCallback(() => {
    if (
      !tempValueProduct ||
      tempValueProduct === sellerInfo[fieldToEditProduct]
    ) {
      setIsEditProduct(false);
    } else if (fieldToEditProduct) {
      formik.setFieldValue(fieldToEditProduct, tempValueProduct);
      formik.handleSubmit();
    }
  }, [fieldToEditProduct, formik, sellerInfo, tempValueProduct]);

  const handleCloseModeEditProduct = () => {
    setIsEditProduct(false);
    setTempValueProduct('');
    setErroMessageProduct('');
  };

  const handleChangeOrdering = async (
    event: React.ChangeEvent<{ name?: string; value: unknown }>,
  ) => {
    const { value } = event.target;
    setTempValueProduct(value as string);

    if (fieldToEditProduct) {
      try {
        await validationSchemaProductOrdering.validateAt(fieldToEditProduct, {
          [fieldToEditProduct]: value,
        });
        setErroMessageProduct('');
        formik.setFieldTouched(fieldToEditProduct, true, false);
      } catch (err: any) {
        setErroMessageProduct(err.errors[0]);
        formik.setFieldTouched(fieldToEditProduct, true);
      }
    }
  };

  return (
    <Box mt={2}>
      {productOrderingFields(sellerInfo?.title).map((field) => (
        <EditableTextField
          key={field.name}
          field={field}
          value={formik.values[field.name]}
          onEditClick={() =>
            handleEditContactClick(field.name, field.label, field.placeholder)
          }
        />
      ))}
      {isEditProduct && (
        <EditCompanyInputModal
          isModalOpen={isEditProduct}
          handleCloseModal={handleCloseModeEditProduct}
          handleSubmitModal={handleSaveFormikProduct}
          approveNameButton={t('Save')}
          value={tempValueProduct}
          error={Boolean(erroMessageProduct)}
          helperText={erroMessageProduct}
          label={labelToEditProduct}
          fieldToEdit={fieldToEditProduct}
          handleChange={handleChangeOrdering}
          placeholder={placeholderToEditProduct}
        />
      )}
    </Box>
  );
};
