import { UserManagerSettings } from 'oidc-client-ts';

const appUrl =
  process.env.REACT_APP_URL ||
  `${window.location.protocol}//${window.location.hostname}`;
const authorityUrl =
  process.env.REACT_APP_AUTHORITY ||
  `${window.location.protocol}//auth.${window.location.hostname}`;
const clientId = process.env.REACT_APP_ZITADEL_CLIENT_ID;

const authConfig: UserManagerSettings = {
  authority: authorityUrl,
  redirect_uri: `${appUrl}/auth`,
  post_logout_redirect_uri: `${appUrl}`,
  scope: 'openid profile email',
  client_id: clientId,
  response_type: 'code',
  response_mode: 'query',
};

export default authConfig;
