import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { SellersService } from 'app/providers/api/services';
import { useApiQuery } from 'shared/hooks/useApiQuery';
import { Seller } from 'shared/types/Sellers';

export default () => {
  const { sellerId } = useParams();
  const [sellerInfo, setSellerInfo] = useState<Seller | null>(null);

  const { isLoading, refetch: refetchSellerInfo } = useApiQuery(
    ['getSeller', sellerId],
    () => SellersService.fetchSeller(sellerId),
    {
      onSuccess: (data) => {
        setSellerInfo(data);
      },
    },
  );

  return {
    isLoading,
    sellerInfo,
    refetchSellerInfo,
  };
};
