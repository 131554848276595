import { useCallback } from 'react';
import { Navigate } from 'react-router-dom';
import { getRouteForbidden, getRouteMain } from 'shared/constants/routerConst';
import { UserRole } from 'shared/constants/userRole';
import useAuth from 'shared/hooks/useAuth';

interface RequireAuthProps {
  children: JSX.Element;
  roles?: UserRole[];
}

export function RequireAuth({ children, roles }: RequireAuthProps) {
  const { authenticated, userRoles } = useAuth();

  const hasRequiredRole = useCallback(
    (userRoles: Array<UserRole>) =>
      userRoles.some((role) => roles.includes(role)),
    [roles],
  );

  if (!authenticated) {
    return <Navigate to={getRouteMain()} replace />;
  }

  if (!!roles && !hasRequiredRole(userRoles)) {
    return <Navigate to={getRouteForbidden()} replace />;
  }

  return children;
}
