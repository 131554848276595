import { useEffect } from 'react';
import { isJestRuntime } from 'shared/constants/envVariables';

export function useInitialEffect(
  callback: () => void | Promise<void>,
  blockForTests?: boolean,
) {
  useEffect(() => {
    if (blockForTests && isJestRuntime) {
      return;
    }
    callback();
    // eslint-disable-next-line
  }, []);
}
