import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent,
} from '@mui/material';
import { Modal } from 'shared/ui/Modal';

interface EditCompanyInputModalProps {
  isModalOpen: boolean;
  handleCloseModal: () => void;
  handleSubmitModal: () => void;
  approveNameButton: string;
  label: string;
  fieldToEdit: string | null;
  value: string;
  isDropDown?: boolean;
  error?: boolean | undefined;
  helperText?: string;
  options?: { label: string; value: string }[];
  handleChange: (
    event:
      | React.ChangeEvent<{ name?: string; value: unknown }>
      | SelectChangeEvent<string>,
  ) => void;
  maskRef?: any;
  placeholder?: string;
}

export const EditCompanyInputModal = ({
  isModalOpen,
  handleCloseModal,
  handleSubmitModal,
  approveNameButton,
  label,
  fieldToEdit,
  value,
  isDropDown,
  error,
  helperText,
  options,
  handleChange,
  maskRef,
  placeholder,
}: EditCompanyInputModalProps) => {
  return (
    <Modal
      disabled={error}
      isModalOpen={isModalOpen}
      handleCloseModal={handleCloseModal}
      handleSubmitModal={handleSubmitModal}
      approveNameButton={approveNameButton}
    >
      {isDropDown ? (
        <FormControl fullWidth sx={{ marginY: '16px' }}>
          <InputLabel>{label}</InputLabel>
          <Select
            autoFocus
            label={label}
            name={fieldToEdit}
            value={value}
            onChange={(event) =>
              handleChange(event as SelectChangeEvent<string>)
            }
          >
            {options?.map(({ label, value }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <TextField
          onFocus={(e) =>
            e.currentTarget.setSelectionRange(
              e.currentTarget.value.length,
              e.currentTarget.value.length,
            )
          }
          multiline={fieldToEdit === 'companyMission'}
          minRows={fieldToEdit === 'companyMission' ? 3 : undefined}
          error={error}
          helperText={helperText}
          inputProps={{
            ref: maskRef,
            maxLength: 1750,
          }}
          placeholder={placeholder || ''}
          autoFocus
          fullWidth
          label={label}
          name={fieldToEdit}
          value={value}
          onChange={(event) =>
            handleChange(
              event as React.ChangeEvent<{ name?: string; value: unknown }>,
            )
          }
        />
      )}
    </Modal>
  );
};
