import { memo, useEffect } from 'react';
import { AuthService } from 'shared/config/oidcClient/authServices';
// import * as Sentry from '@sentry/react';

export const LoginRedirectPage = memo(() => {
  const onLogin = async () => {
    const authService = new AuthService();
    await authService.login();
  };

  useEffect(() => {
    onLogin().catch(() => {
      // Sentry.captureException(error);
    });
  }, []);

  return <></>;
});
