export enum AppRoutes {
  MAIN = 'main',
  AUTH = 'auth',
  LOGIN = 'login',
  SELLERS = 'sellers',
  SELLER = 'seller',
  SELLER_COMPANY_INFO = 'seller-company-info',
  SELLER_MARKETING_INFO = 'seller-marketing-info',
  SELLER_POLICIES_INFO = 'seller-policies-info',
  SELLER_USERS_INFO = 'seller-users-info',

  PRIVACY_POLICY = 'privacy-policy',
  TERMS_OF_SERVICE = 'terms-of-service',
  PRIVACY_POLICY_EMBEDED = 'privacy-policy-embeded',
  TERMS_OF_SERVICE_EMBEDED = 'terms-of-service-embeded',
  // error pages
  FORBIDDEN = 'forbidden',
  ACCESS_DENIED = 'access-denied',
  NOT_FOUND_PAGE = 'not-found-page',
  // last
  NOT_FOUND = 'not-found',
}

export const getRouteMain = () => '/';
export const getRouteAuth = () => '/auth';
export const getRouteLogin = () => '/login';
export const getRouteSellers = () => `/sellers`;

export const getRouteSellerPage = (id: string) => `/sellers/${id}`;
export const getRouteCompanySellerPage = (id: string) =>
  `/sellers/${id}/company-info`;
export const getRouteMarketingSellerPage = (id: string) =>
  `/sellers/${id}/marketing-info`;
export const getRoutePoliciesSellerPage = (id: string) =>
  `/sellers/${id}/policies-info`;
export const getRouteUsersSellerPage = (id: string) =>
  `/sellers/${id}/users-info`;

//TODO: replace route when Home page will be ready
export const getRouteHome = () => '/sellers';

export const getRoutePrivacyPolicy = () => `/privacy-policy`;
export const getRouteTermsOfService = () => `/terms-of-service`;
export const getRoutePrivacyPolicyEmbeded = () => `/profile/privacy-policy`;
export const getRouteTermsOfServiceEmbeded = () => `/profile/terms-of-service`;

//error pages
export const getRouteAccessDenied = () => '/access-denied';
export const getRouteForbidden = () => '/forbidden';
export const getRouteNotFound = () => '/not-found';
