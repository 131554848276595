/* eslint-disable max-len */
import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, IconButton } from '@mui/material';
import { ModeEditOutlineOutlined } from '@mui/icons-material';
import { EditMarketingInputModal } from 'shared/ui/EditMarketingInputModal';

export const SpecialtiesInfoForm = ({
  sellerId,
  marketingAllTags,
  marketingSellerTags,
  handleUpdateMarketingTags,
}: {
  sellerId: string;
  marketingAllTags: string[];
  marketingSellerTags: string[];
  handleUpdateMarketingTags: ({
    idOfSeller,
    data,
  }: {
    idOfSeller: string;
    data: { title: string }[];
  }) => void;
}) => {
  const { t } = useTranslation();
  const [isEditTags, setIsEditTags] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState(marketingSellerTags);

  const handleSaveMarketingTags = useCallback(() => {
    const updatedTagsData: { title: string }[] = selectedOptions?.map(
      (tag) => ({ title: tag }),
    );
    handleUpdateMarketingTags({
      idOfSeller: sellerId,
      data: updatedTagsData,
    });
    setIsEditTags(false);
  }, [handleUpdateMarketingTags, selectedOptions, sellerId]);

  const handleChangeMarketingTags = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string[],
  ) => {
    setSelectedOptions(value);
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          position: 'relative',
          width: '100%',
        }}
      >
        <Typography mt="4px" typography="poppins.subtitle1">
          {t('Specialties')}
        </Typography>
        <IconButton
          sx={{
            position: 'absolute',
            right: 0,
            top: '50%',
            transform: 'translateY(-50%)',
            visibility: 'hidden',
          }}
          className="editIconButton"
          onClick={() => setIsEditTags(true)}
        >
          <ModeEditOutlineOutlined color="primary" />
        </IconButton>
      </Box>
      <Box mt={2}>
        {marketingSellerTags?.length ? (
          <>
            {marketingSellerTags?.map((tag) => (
              <Typography
                typography="openSans.body1"
                sx={{
                  display: 'inline-flex',
                  margin: '3px',
                  backgroundColor: '#267C9B26',
                  color: '#267C9B',
                  padding: '4px 9px',
                  height: '32px',
                  borderRadius: '100px',
                }}
                key={tag}
              >
                {tag}
              </Typography>
            ))}
          </>
        ) : (
          <>
            <Typography mt="4px" typography="openSans.body2" color="#000000D9">
              {t('No specialties assigned')}
            </Typography>
            <Typography
              mt="12px"
              typography="openSans.caption"
              color="#00000080"
            >
              {t(
                'Adding specialties will help identify your business and the products/services you offer to buyers in the RE-OPS Marketplace. You can add specialties like MRO Supplies, Plumbing, Electrical, and more.',
              )}
            </Typography>
          </>
        )}
      </Box>
      {isEditTags && (
        <EditMarketingInputModal
          titleModal={t('Vendor Specialties')}
          subTitleModal={t(
            'Select specialties in the order you want them displayed to your customers.',
          )}
          isModalOpen={isEditTags}
          handleCloseModal={() => setIsEditTags(false)}
          handleSubmitModal={handleSaveMarketingTags}
          approveNameButton={t('Save specialties')}
          handleChange={handleChangeMarketingTags}
          selectedOptions={marketingSellerTags}
          allOptions={marketingAllTags}
        />
      )}
    </>
  );
};
