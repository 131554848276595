import { Box, Typography, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const NeedAnyAssistance = () => {
  const { t } = useTranslation();
  const supportMail = `help@re-ops.co`;

  return (
    <>
      <Box
        display="flex"
        gap="4px"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <Box>
          <Typography typography="openSans.body1">
            {t('Need any assistance')}?{' '}
          </Typography>
        </Box>
        <Box display="flex" gap="4px" mb="16px">
          <Typography typography="openSans.body1">
            {t('Please contact')}
          </Typography>
          <Typography
            component={Link}
            color={'blue.500'}
            href={`mailto: ${supportMail}`}
            typography="openSans.body1"
            borderColor="blue.500"
          >
            {supportMail}
          </Typography>{' '}
        </Box>
      </Box>
    </>
  );
};
