import { Suspense } from 'react';
import { Box } from '@mui/material';
import { useInitialEffect } from 'shared/hooks/useInitialEffect';
import { usePublicPageDetector } from 'shared/hooks/usePublicPageDetector';
import { AppRouter } from './providers/router';
import { AuthService } from 'shared/config/oidcClient/authServices';
import { Header } from 'shared/ui/Header';
import { Navbar } from 'shared/ui/Navbar';
import { Toast } from 'shared/ui/Toast/Toast';
import useAuth from 'shared/hooks/useAuth';

const App = () => {
  const { login, authenticated, setIsInited, isInited, setIsloading } =
    useAuth();
  const isPublicPage = usePublicPageDetector();

  useInitialEffect(async () => {
    setIsloading(true);
    try {
      const authService = new AuthService();

      await authService.getUser().then(async (user) => {
        if (user) {
          await login(user);
        }
      });
      setIsInited(true);
      setIsloading(false);
    } catch (error) {
      setIsloading(false);
      console.log('initialize app error', error);
    }
  });

  const nav = isInited && authenticated;

  return (
    <Box minHeight="100vh" display="flex">
      <Box flexGrow={1}>
        <Suspense fallback="">
          {!isPublicPage && authenticated && <Header />}
          <Box bgcolor="default" display="flex">
            {!isPublicPage && nav && <Navbar />}
            <Box flex="1 1">{isInited && <AppRouter />}</Box>
          </Box>
        </Suspense>
      </Box>
      <Toast />
    </Box>
  );
};

export default App;
